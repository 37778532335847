import { createLinkBridgeProvider } from '@webview-bridge/react';
import { AppBridge } from 'types/bridge';

export const { BridgeProvider, useBridgeStore, useBridgeStatus, useBridgeLoose } = createLinkBridgeProvider<AppBridge>({
  throwOnError: true,
  timeout: 0,
  onReady: () => {
    console.log('bridge is ready');
  },
});
